import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["indicator"]
  static values = {
    formPath: String
  }

  connect() {
    this.altInput = this.element.closest(".field-unit__field").querySelector("input, textarea")
  }

  fire(e) {
    e.preventDefault()
    this.indicatorTarget.classList.remove("hidden")

    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    fetch(this.formPathValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
    })
    .then(response => response.json())
    .then(data => {
      this.indicatorTarget.classList.add("hidden")
      this.altInput.value = data.join("\n")
    })

  }
}
