import { Controller } from "stimulus"

export default class extends Controller {
  toggle(e) {
    e.preventDefault()

    // post update to backend to log change
    fetch(this.element.dataset.toggleUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
      }
    })

    // toggle dark mode in frontend
    const htmlElement = document.documentElement
    if (htmlElement.classList.contains("dark")) {
      htmlElement.classList.remove("dark")
    } else {
      htmlElement.classList.add("dark")
    }
  }
}
